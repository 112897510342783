<nav class="menu-wrapper" [ngClass]="{ 'open-all': isMenuOpen }">
  <div class="outer" (click)="closeMenu()"></div>
  <div class="scroller">
    <ul class="menu">
      <li [ngClass]="{
        open: isMenuOpen,
        active:
          (this.router.url.startsWith('/myceres/settings')) &&
          !isMenuOpen,
        extend: isMenuOpen
      }">
        <a routerLink="/myceres/settings" routerLinkActive="active" class="d-flex align-items-center">
          <div class="avatar-placeholder d-flex align-items-center justify-content-center"
            *ngIf="appUserService.loggedInUser$ | async">
            {{ currentUser ? currentUser.nameShort : '' }}
          </div>
        </a>
        <ul class="submenu-avatar">
          <li [ngClass]="{ avatarOpen: isMenuOpen }" (click)="closeMenu()" routerLink="/myceres/settings"
            routerLinkActive="active">
            <p class="employee-name">{{ currentUser ? currentUser.name : '' }}</p>
            <p class="employee-department">{{ currentUser ? '(' +
              currentUser.details.department + ")" : '' }}</p>
          </li>
        </ul>
      </li>

      <!-- home -->
      <li [ngClass]="{ active: this.router.url === '/' }">
        <a routerLink="/" routerLinkActive="active" class="d-flex align-items-center" (click)="closeMenu()" >
          <i class="icon a-icon a-icon--home" [matTooltip]="'shared.miscellaneous.home' | transloco"
             [matTooltipPosition]="'right'" matTooltipClass="pwo-tooltip"></i>{{ 'shared.miscellaneous.home' | transloco
          }}
        </a>
      </li>

      <li *ngIf="appUserService.hasPermission(permission.task)" [ngClass]="{
          open: navigation[0].open === true && isMenuOpen,
          active:
            this.router.url.startsWith('/tasks') && !isMenuOpen
        }" class="test">
        <a (click)="openSubMenu($event, 0)">
          <i class="icon a-icon a-icon--edit"
            [matTooltip]="isMenuOpen ? '' : ('my-ceres.general.my-ceres-title' | transloco)"
            [matTooltipPosition]="'right'" matTooltipClass="pwo-tooltip"></i>{{ 'my-ceres.general.my-ceres-title' |
          transloco }}
          <i class="icon a-icon a-icon--arrow-right-small nav-icon-right arrow"></i>
          <i class="icon a-icon a-icon--arrow-down-small nav-icon-down arrow"></i>
          <!-- <i class="nav-icon-down material-icons arrow">keyboard_arrow_down</i><i class="nav-icon-up material-icons arrow">keyboard_arrow_up</i> -->
        </a>
        <ul class="submenu" [ngClass]="{ open: navigation[0].open === true }">
          <li (click)="closeMenu()" routerLink="/tasks/status/open" routerLinkActive="active">
            <a>{{ 'my-ceres.general.tasks-title' | transloco }}</a>
          </li>
        </ul>
      </li>
      <!-- business partner -->
      <ng-container *ceresRequiredFeatures="[features.businessPartner.enabled]">
        <li *ngIf="!(appUserService.isHoursTracker() || appUserService.isUser())" [ngClass]="{
            open: navigation[1].open === true && isMenuOpen,
            active:
              this.router.url.startsWith('/businesspartner') && !isMenuOpen
          }">
          <a (click)="openSubMenu($event, 1)">
            <i class="icon a-icon a-icon--forum"
              [matTooltip]="isMenuOpen ? '' : ('businesspartner.general.title' | transloco)"
              [matTooltipPosition]="'right'" matTooltipClass="pwo-tooltip"></i>{{ 'businesspartner.general.title' |
            transloco }}
            <i class="icon a-icon a-icon--arrow-right-small nav-icon-right arrow"></i>
            <i class="icon a-icon a-icon--arrow-down-small nav-icon-down arrow"></i>
          </a>
          <ul class="submenu">
            <li (click)="closeMenu()" routerLink="/businesspartner" routerLinkActive="active">
              <a>{{ 'businesspartner.general.title' | transloco }}</a>
            </li>
            <!-- <li>
              <a>{{ 'businesspartner.general.campaign-title' | transloco }}</a>
            </li> -->
          </ul>
        </li>
      </ng-container>

      <!-- sales -->
      <ng-container *ceresRequiredFeatures="[features.sales.enabled]">
        <li *ngIf="
            appUserService.hasAnyPermission([
              permission.bop.readAll,
              permission.salesActivity.readAll,
              permission.contract.readAll,
              permission.controlling.salesFunnel
            ])
          " [ngClass]="{
            open: navigation[2].open === true && isMenuOpen,
            active:
              (this.router.url.startsWith('/sales-activity') ||
                this.router.url.startsWith('/bop') ||
                this.router.url.startsWith('/controlling/sales-funnel') ||
                this.router.url.startsWith('/contract')) &&
              !isMenuOpen
          }">
          <a (click)="openSubMenu($event, 2)">
            <i class="icon a-icon a-icon--investor-relations"
              [matTooltip]="isMenuOpen ? '' : ('sales-activity.general.sales-title' | transloco)"
              [matTooltipPosition]="'right'" matTooltipClass="pwo-tooltip"></i>{{ 'sales-activity.general.sales-title' |
            transloco }}
            <i class="icon a-icon a-icon--arrow-right-small nav-icon-right arrow"></i>
            <i class="icon a-icon a-icon--arrow-down-small nav-icon-down arrow"></i>
          </a>
          <ul class="submenu" [ngClass]="{ open: navigation[0].open === true }">
            <ng-container *ceresRequiredFeatures="[features.sales.businessOpportunities]">
              <li (click)="closeMenu()" *ngIf="appUserService.hasPermission(permission.bop.readAll)" routerLink="/bop"
                routerLinkActive="active">
                <a>{{ 'bop.general.bop-title' | transloco }}</a>
              </li>
            </ng-container>
            <ng-container *ceresRequiredFeatures="[features.sales.salesActivities]">
              <li (click)="closeMenu()" *ngIf="
                  appUserService.hasPermission(permission.salesActivity.readAll)
                " routerLink="/sales-activity" routerLinkActive="active">
                <a>{{ 'sales-activity.general.title' | transloco }}</a>
              </li>
            </ng-container>
            <ng-container *ceresRequiredFeatures="[features.sales.salesFunnel]">
              <li (click)="closeMenu()" *ngIf="
                  appUserService.hasPermission(permission.controlling.salesFunnel)
                " routerLink="/controlling/sales-funnel" routerLinkActive="active">
                <a>{{ 'salesfunnel.general.title' | transloco }}</a>
              </li>
            </ng-container>
            <ng-container *ceresRequiredFeatures="[features.sales.offers]">
              <li (click)="closeMenu()" *ngIf="appUserService.hasPermission(permission.contract.readAll)"
                routerLink="/contract" routerLinkActive="active">
                <a>{{ 'offers.general.title' | transloco }}</a>
              </li>
            </ng-container>
          </ul>
        </li>
      </ng-container>

      <!-- projects -->
      <ng-container *ceresRequiredFeatures="[features.projects.enabled]">
        <li [ngClass]="{
            open: navigation[3].open === true && isMenuOpen,
            active:
              ((this.router.url.startsWith('/projects') &&
                this.router.url !== '/projects/favorites/') ||
                this.router.url.includes('/accounting/overview') ||
                this.router.url.startsWith('/accounting/merchant') ||
                this.router.url.startsWith('/projects/extern')) &&
              !isMenuOpen
          }">
          <a *ngIf="
              appUserService.hasAnyPermission([
                permission.project.readAll,
                permission.project.readOwn
              ])
            " (click)="openSubMenu($event, 3)">
            <i class="icon a-icon a-icon--feature"
              [matTooltip]="isMenuOpen ? '' : ('projects.general.title-projects' | transloco)"
              [matTooltipPosition]="'right'" matTooltipClass="pwo-tooltip"></i>{{ 'projects.general.title-projects' |
            transloco }}
            <i class="icon a-icon a-icon--arrow-right-small nav-icon-right arrow"></i>
            <i class="icon a-icon a-icon--arrow-down-small nav-icon-down arrow"></i>
          </a>
          <ul class="submenu">
            <li (click)="closeMenu()" [routerLinkActiveOptions]="{ exact: true }" routerLink="/projects"
              routerLinkActive="active">
              <a>{{ 'projects.general.title-projects' | transloco }}</a>
            </li>
            <ng-container *ngIf="appUserService.hasPermission(permission.project.readAll)">
              <ng-container *ceresRequiredFeatures="[features.projects.groups]">
                <li (click)="closeMenu()" [routerLinkActiveOptions]="{ exact: true }" routerLink="/projectgroup"
                  routerLinkActive="active">
                  <a>{{
                    'projects.project-properties.project-group' | transloco
                    }}</a>
                </li>
              </ng-container>
              <ng-container *ceresRequiredFeatures="[features.projects.charging]">
                <li (click)="closeMenu()" *ngIf="!appUserService.isMerchant()" routerLink="/accounting/overview"
                  routerLinkActive="active">
                  <a>{{ 'projects.general.title-accounting' | transloco }}</a>
                </li>
                <li (click)="closeMenu()" *ngIf="appUserService.isMerchant()" routerLink="/accounting/merchant"
                  routerLinkActive="active">
                  <a>{{ 'projects.general.title-accounting' | transloco }}</a>
                </li>
              </ng-container>
              <!-- <li
              *ngIf="!appUserService.isHoursTracker && !appUserService.isTeamMember()"
              (click)="closeMenu()"
              [routerLinkActiveOptions]="{ exact: true }"
              routerLink="/projects/extern"
              routerLinkActive="active"
            >
              <a>{{ 'projects.project-external-costs.title' | transloco }}</a>
            </li> -->
              <ng-container *ceresRequiredFeatures="[features.projects.nrsView]">
                <li (click)="closeMenu()" *ngIf="appUserService.hasPermission(permission.project.nrs)"
                  [routerLinkActiveOptions]="{ exact: true }" routerLink="/projects/nrs" routerLinkActive="active">
                  <a>{{ 'projects.project-nrs-view.title' | transloco }}</a>
                </li>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="appUserService.hasPermission(permission.project.preStaffing.readAll)">
              <ng-container *ceresRequiredFeatures="[features.projects.preStaffing]">
                <li (click)="closeMenu()" [routerLinkActiveOptions]="{ exact: true }" routerLink="/prestaffing" routerLinkActive="active">
                  <a>{{ 'projects.pre-staffing.title' | transloco }}</a>
                </li>
              </ng-container>
            </ng-container>
          </ul>
        </li>
      </ng-container>

      <!-- Time management -->
      <ng-container *ceresRequiredFeatures="[features.timeManagement.enabled]">
        <li *ngIf="
            appUserService.hasAnyPermission([
              permission.timesheet,
              permission.absences,
              permission.workload.readAll,
              permission.capacities
            ])
          " [ngClass]="{
            open: navigation[4].open === true && isMenuOpen,
            active:
              (this.router.url.startsWith('/timesheet') ||
                this.router.url.startsWith('/capacities') ||
                this.router.url.startsWith('/absences') ||
                this.router.url.startsWith('/workload') ||
                this.router.url.startsWith('/projects/favorites')) &&
              !isMenuOpen
          }">
          <a (click)="openSubMenu($event, 4)">
            <i class="icon a-icon a-icon--clock"
              [matTooltip]="isMenuOpen ? '' : ('time-management.general.title' | transloco)"
              [matTooltipPosition]="'right'" matTooltipClass="pwo-tooltip"></i>{{ 'time-management.general.title' |
            transloco }}
            <i class="icon a-icon a-icon--arrow-right-small nav-icon-right arrow"></i>
            <i class="icon a-icon a-icon--arrow-down-small nav-icon-down arrow"></i>
          </a>
          <ul class="submenu">
            <ng-container *ceresRequiredFeatures="[this.features.timeManagement.timeSheets]">
              <li (click)="closeMenu()" routerLink="/timesheet" routerLinkActive="active"
                *ngIf="appUserService.hasPermission(permission.timesheet)">
                <a>{{
                  'time-management.general.time-sheets-title' | transloco
                  }}</a>
              </li>
            </ng-container>
            <ng-container *ceresRequiredFeatures="[this.features.timeManagement.capacity]">
              <li (click)="closeMenu()" routerLink="/capacities" routerLinkActive="active"
                *ngIf="appUserService.hasPermission(permission.capacities)">
                <a>{{
                  'time-management.general.capacity-planning-title' | transloco
                  }}</a>
              </li>
            </ng-container>
            <ng-container *ceresRequiredFeatures="[this.features.timeManagement.absences]">
              <li *ngIf="appUserService.hasPermission(permission.absences)" (click)="closeMenu()" routerLink="/absences"
                routerLinkActive="active">
                <a>{{
                  'time-management.general.absences-title' | transloco
                  }}</a>
              </li>
            </ng-container>
            <ng-container *ceresRequiredFeatures="[this.features.timeManagement.workload]">
              <li (click)="closeMenu()" *ngIf="appUserService.hasPermission(permission.workload.readAll)"
                routerLink="/workload" routerLinkActive="active">
                <a>{{
                  'time-management.general.workload-title' | transloco
                  }}</a>
              </li>
            </ng-container>
            <ng-container *ceresRequiredFeatures="[
                this.features.timeManagement.transactionalTimeTracking
              ]">
              <li (click)="closeMenu()" *ngIf="appUserService.hasPermission(permission.workload.readAll)"
                routerLink="/transactional-hours" routerLinkActive="active">
                <a>{{
                  'time-management.general.transactional-hours-title'
                  | transloco
                  }}</a>
              </li>
            </ng-container>
          </ul>
        </li>
      </ng-container>
      <!--Reporting-->
      <ng-container *ceresRequiredFeatures="[features.reporting.enabled]">
        <li [ngClass]="{
            open: navigation[5].open === true && isMenuOpen,
            active:
              (this.router.url.startsWith('/controlling') ||
                this.router.url.startsWith('/reports') ||
                this.router.url.startsWith('/cost-report') ||
                this.router.url.startsWith('/working-hours-report') ||
                this.router.url === '/controlling' ||
                this.router.url.startsWith('/gmsreporting') ||
                this.router.url.startsWith('/controlling/quality-projects')) &&
              !isMenuOpen
          }" *ngIf="appUserService.hasPermission(permission.reports)">
          <a (click)="openSubMenu($event, 5)">
            <i class="icon a-icon a-icon--control" [matTooltip]="'reporting.general.title' | transloco"
              [matTooltipPosition]="'right'" matTooltipClass="pwo-tooltip"></i>{{ 'reporting.general.title' | transloco
            }}
            <i class="icon a-icon a-icon--arrow-right-small nav-icon-right arrow"></i>
            <i class="icon a-icon a-icon--arrow-down-small nav-icon-down arrow"></i>
          </a>
          <ul class="submenu" [ngClass]="{ open: navigation[7].open === true }">
            <ng-container *ceresRequiredFeatures="[features.reporting.dataQuality]">
              <li (click)="closeMenu()" *ngIf="
                  appUserService.hasPermission(permission.controlling.quality)
                " routerLink="/controlling/quality-projects" routerLinkActive="active">
                <a>{{ 'reporting.general.data-quality-title' | transloco }}</a>
              </li>
            </ng-container>
            <ng-container *ceresRequiredFeatures="[features.reporting.pulseDashboard]">
              <li>
                <a (click)="closeMenu()" href="https://pulse.siemens.cloud/project/gmsreporting/dash/2506"
                  target="_blank" *ngIf="appUserService.hasPermission(permission.reports)">
                  {{ 'reporting.general.pulse-title' | transloco }}
                </a>
              </li>
            </ng-container>
            <ng-container *ceresRequiredFeatures="[features.reporting.costReport]">
              <li *ngIf="appUserService.hasPermission(permission.costReport)" (click)="closeMenu()"
                routerLink="/cost-report" routerLinkActive="active">
                <a class="icon nav-icon icon-myceres">{{
                  'reporting.general.cost-report-title' | transloco
                  }}</a>
              </li>
            </ng-container>
            <ng-container *ceresRequiredFeatures="[features.reporting.workingHoursReport]">
              <li *ngIf="
                  appUserService.hasPermission(permission.workingHoursReport)
                " (click)="closeMenu()" routerLink="/working-hours-report" routerLinkActive="active">
                <a class="icon nav-icon icon-myceres">{{
                  'time-management.general.working-hours-report' | transloco
                  }}</a>
              </li>
            </ng-container>
            <ng-container *ceresRequiredFeatures="[features.reporting.gmsReportingDemo]">
              <li *ngIf="appUserService.hasPermission(permission.reports)" (click)="closeMenu()"
                routerLink="/gmsreporting" routerLinkActive="active">
                <a>{{ 'reporting.general.gms-reporting-title' | transloco }}</a>
              </li>
            </ng-container>
            <ng-container *ceresRequiredFeatures="[features.reporting.productivityReport]">
              <li *ngIf="
                  appUserService.hasPermission(permission.productivityReport)
                " (click)="closeMenu()" routerLink="/reports/productivity-report" routerLinkActive="active">
                <a>{{ 'reporting.general.productivity-title' | transloco }}</a>
              </li>
            </ng-container>
          </ul>
        </li>
      </ng-container>

      <!-- employee -->
      <ng-container *ceresRequiredFeatures="[features.employees.enabled]">
        <li *ngIf="appUserService.hasPermission(permission.employee.readAll)" [ngClass]="{
            open: navigation[6].open === true && isMenuOpen,
            active:
              (this.router.url.startsWith('/employees')) &&
              !isMenuOpen
          }">
          <a (click)="openSubMenu($event, 6)">
            <i class="icon a-icon a-icon--service"
              [matTooltip]="isMenuOpen ? '' : ('employee.general.employee-title' | transloco)"
              [matTooltipPosition]="'right'" matTooltipClass="pwo-tooltip"></i>{{ 'employee.general.employee-title' |
            transloco }}
            <i class="icon a-icon a-icon--arrow-right-small nav-icon-right arrow"></i>
            <i class="icon a-icon a-icon--arrow-down-small nav-icon-down arrow"></i>
          </a>
          <ul class="submenu">
            <li (click)="closeMenu()" *ngIf="
                appUserService.isAdmin() ||
                appUserService.isTeamLeader() ||
                appUserService.isTeamLeaderCharging() ||
                appUserService.isGroupLeader() ||
                appUserService.isSupport()
              " routerLink="/employees" routerLinkActive="active">
              <a>{{ 'employee.general.employees-title' | transloco }}</a>
            </li>
            <ng-container *ceresRequiredFeatures="[features.employees.skills]">
              <li (click)="closeMenu()" routerLink="/employees/skills" routerLinkActive="active">
                <a>{{ 'employee.general.skills-title' | transloco }}</a>
              </li>
            </ng-container>
            <!-- <li>
              <a>{{ 'employee.general.profile-title' | transloco }}</a>
            </li> -->
          </ul>
        </li>
      </ng-container>
      <!-- Confluence tool -->
      <ng-container *ceresRequiredFeatures="[features.faq.enabled]">
        <li>
          <a (click)="closeMenu()" href="https://confluence.gms.siemens.cloud/display/CER/CERES+Knowledge+Base"
            target="_blank">
            <i class="icon a-icon a-icon--chat" [matTooltip]="'shared.miscellaneous.faq' | transloco"
              [matTooltipPosition]="'right'" matTooltipClass="pwo-tooltip"></i>{{ 'shared.miscellaneous.faq' | transloco
            }}
          </a>
        </li>
      </ng-container>

      <!-- ticket tool -->
      <ng-container *ceresRequiredFeatures="[features.ticketTool.enabled]">
        <li [ngClass]="{ open: navigation[8].open === true && isMenuOpen }">
          <a (click)="closeMenu()" href="https://jira.gms.siemens.cloud/servicedesk/customer/portal/27" target="_blank">
            <i class="icon a-icon a-icon--support" [matTooltip]="'shared.miscellaneous.ticket-tool' | transloco"
              [matTooltipPosition]="'right'" matTooltipClass="pwo-tooltip"></i>{{ 'shared.miscellaneous.ticket-tool' |
            transloco }}
          </a>
        </li>
      </ng-container>

      <!-- services -->
      <ng-container *ceresRequiredFeatures="[features.services.enabled]">
        <li *ngIf="appUserService.hasPermission(permission.services.readAll)" [ngClass]="{
            open: navigation[9].open === true && isMenuOpen,
            active:
              (
                this.router.url.startsWith('/services')) &&
              !isMenuOpen
          }">
          <a (click)="openSubMenu($event, 9)">
            <i class="icon a-icon a-icon--shopping-cart"
              [matTooltip]="isMenuOpen ? '' : ('services.general.title' | transloco)" [matTooltipPosition]="'right'"
              matTooltipClass="pwo-tooltip"></i>{{ 'services.general.title' | transloco }}
            <i class="icon a-icon a-icon--arrow-right-small nav-icon-right arrow"></i>
            <i class="icon a-icon a-icon--arrow-down-small nav-icon-down arrow"></i>
          </a>
          <ul class="submenu">
            <li (click)="closeMenu()" routerLink="/services" routerLinkActive="active">
              <a>{{ 'services.general.title' | transloco }}</a>
            </li>
          </ul>
        </li>
      </ng-container>

      <!-- admin -->
      <ng-container *ceresRequiredFeatures="[features.administration.enabled]">
        <li *ngIf="appUserService.hasPermission(permission.admin)" [ngClass]="{
            open: navigation[10].open === true && isMenuOpen,
            active: this.router.url.startsWith('/admin') && !isMenuOpen
          }">
          <a (click)="openSubMenu($event, 10)">
            <i class="icon a-icon a-icon--settings"
              [matTooltip]="isMenuOpen ? '' : ('admin.general.admin-title' | transloco)" [matTooltipPosition]="'right'"
              matTooltipClass="pwo-tooltip"></i>{{ 'admin.general.admin-title' | transloco }}
            <i class="icon a-icon a-icon--arrow-right-small nav-icon-right arrow"></i>
            <i class="icon a-icon a-icon--arrow-down-small nav-icon-down arrow"></i>
          </a>
          <ul class="submenu">
            <li (click)="closeMenu()" routerLink="/admin" routerLinkActive="active">
              <a>{{ 'admin.general.admin-title' | transloco }}</a>
            </li>
            <li>
              <a>{{ 'admin.general.news-title' | transloco }}</a>
            </li>
          </ul>
        </li>
      </ng-container>
    </ul>
  </div>
</nav>
