<form
  *ngIf="formGroup && !busy"
  class="container"
  [formGroup]="formGroup"
  (ngSubmit)="save()"
>
  <div class="row top-row">
    <div class="col-md-5 header mb-xl-4 mb-lg-4 mb-md-4">
      <div class="header-title">
        <h3 class="main-title">
          {{ 'businesspartner.general.new-title' | transloco }}
        </h3>
        <p class="description">
          {{ 'shared.miscellaneous.user-change-values' | transloco }}
        </p>
      </div>
    </div>
    <div class="col-md-7 my-2 mb-sm-3">
      <div
        class="d-flex flex-wrap justify-content-start justify-content-sm-end gap-10"
      >
        <ceres-button
          *ngIf="
            !closePopup && 'businesspartner.readAll'
              | hasPermission : permissions
          "
          icon="forum"
          routerLink="/businesspartner"
        >
          {{ 'businesspartner.general.title' | transloco }}
        </ceres-button>
        <ceres-button icon="check-mark" type="submit">
          {{ 'shared.buttons.save' | transloco }}
        </ceres-button>
        <ceres-button *ngIf="closePopup" [style]="'red'" icon="close" (click)="close()">
          {{ 'shared.buttons.cancel' | transloco }}
        </ceres-button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 mb-4 form-container">
      <div class="options-form padding">
        <div class="row">
          <div class="col-sm-6" *ngIf="!showEmail">
            <ceres-user-picker
              (selected)="chooseEmployee($event)"
              [extern]="true"
              [fullUser]="true"
              [appUser]="false"
              [multiple]="false"
              [placeholder]="'shared.buttons.scd-add-person' | transloco"
              [value]="businessPartner['user']"
              [condition]="pickerCondition('businessPartner')"
            >
              <!--[required]="formGroup.value.gpType !== 'External'"-->
            </ceres-user-picker>
          </div>
          <div class="col-sm-6" *ngIf="showEmail">
            <mat-form-field class="mat-custom w-100" appearance="fill">
              <mat-label>{{
                'shared.properties.e-mail' | transloco
              }}</mat-label>
              <input
                formControlName="eMail"
                matInput
                (change)="checkBPExists()"
              />
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-form-field class="mat-custom w-100" appearance="fill">
              <mat-label>GID</mat-label>
              <input formControlName="gid" matInput />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <mat-form-field class="mat-custom w-100" appearance="fill">
              <mat-label>{{
                'businesspartner.properties.type' | transloco
              }}</mat-label>
              <mat-select
                formControlName="gpType"
                required
                (selectionChange)="chooseGPType()"
              >
                <mat-option
                  *ngFor="let item of gpTypeOptions | translateOrder"
                  [value]="item"
                >
                  {{
                    isIntern(item)
                      ? tenantName + ' ' + (item.translationKey | transloco)
                      : (item.translationKey | transloco)
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div formArrayName="companyNames" class="row">
          <ng-container
            *ngFor="let company of companyNamesControl; let i = index"
            [formGroupName]="i"
          >
            <div class="col-12">
              <div class="company-names">
                <mat-form-field class="mat-custom w-100" appearance="fill">
                  <mat-label
                    >{{
                      'businesspartner.properties.company-name' | transloco
                    }}
                    {{ i + 1 }}</mat-label
                  >
                  <input formControlName="name" matInput />
                </mat-form-field>
                <mat-icon
                  (click)="addNewRow()"
                  *ngIf="checkCompany(i, company.value.name)"
                  [style.right.px]="i !== 0 ? 36 : 0"
                  class="mat-custom fw-bold"
                  matSuffix
                  >add</mat-icon
                >
                <mat-icon
                  *ngIf="i !== 0"
                  (click)="deleteCompany(i)"
                  class="mat-custom"
                  matSuffix
                  >delete</mat-icon
                >
              </div>
            </div>
          </ng-container>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-form-field class="mat-custom w-100" appearance="fill">
              <mat-label>{{
                'shared.properties.department' | transloco
              }}</mat-label>
              <input formControlName="department" matInput />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6" *ngIf="businessPartnerConfig.validateLabel('business-partner.salutation')">
            <mat-form-field class="mat-custom w-100" appearance="fill">
              <mat-label>{{
                'businesspartner.properties.salutation.title' | transloco
              }}</mat-label>
              <mat-select formControlName="salutation">
                <mat-option value="Mr.">{{
                  'businesspartner.properties.salutation.mr' | transloco
                }}</mat-option>
                <mat-option value="Mrs.">{{
                  'businesspartner.properties.salutation.mrs' | transloco
                }}</mat-option>
                <mat-option value="None">{{
                  'businesspartner.properties.salutation.none' | transloco
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-sm-6" *ngIf="businessPartnerConfig.validateLabel('business-partner.title')">
            <mat-form-field class="mat-custom w-100" appearance="fill">
              <mat-label>{{
                'shared.miscellaneous.title' | transloco
              }}</mat-label>
              <mat-select formControlName="title">
                <mat-option value=""></mat-option>
                <mat-option value="Dr.">{{
                  'businesspartner.properties.title-types.dr' | transloco
                }}</mat-option>
                <mat-option value="Prof.">{{
                  'businesspartner.properties.title-types.prof' | transloco
                }}</mat-option>
                <mat-option value="Prof. Dr."
                  >{{
                    'businesspartner.properties.title-types.prof-dr' | transloco
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <mat-form-field class="mat-custom w-100" appearance="fill">
              <mat-label>{{
                'shared.miscellaneous.firstname' | transloco
              }}</mat-label>
              <input formControlName="firstName" matInput />
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-form-field class="mat-custom w-100" appearance="fill">
              <mat-label>{{
                'shared.miscellaneous.lastname' | transloco
              }}</mat-label>
              <input formControlName="surName" matInput required />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6" *ngIf="businessPartnerConfig.validateLabel('business-partner.subject-area')">
            <mat-form-field class="mat-custom w-100" appearance="fill">
              <mat-label>{{
                'businesspartner.properties.subject-area' | transloco
              }}</mat-label>
              <mat-select formControlName="subjectArea" required>
                <mat-option>
                  <ngx-mat-select-search
                    [formControl]="subjectAreaFilterCtrl"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="
                    let item of subjectAreasOptions | async | translateOrder
                  "
                  [value]="item"
                  >{{ item.translationKey | transloco }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-sm-6" *ngIf="businessPartnerConfig.validateLabel('business-partner.function')">
            <mat-form-field class="mat-custom w-100" appearance="fill">
              <mat-label>{{
                'businesspartner.properties.function' | transloco
              }}</mat-label>
              <mat-select formControlName="function" required>
                <mat-option
                  *ngFor="let item of functions | translateOrder"
                  [value]="item"
                  >{{ item.translationKey | transloco }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6" *ngIf="businessPartnerConfig.validateLabel('business-partner.budget-responsibility')">
            <mat-form-field class="mat-custom w-100" appearance="fill">
              <mat-label>{{
                'businesspartner.properties.budget-responsibility' | transloco
              }}</mat-label>
              <mat-select formControlName="budgetResponsibility">
                <mat-option
                  *ngFor="let item of budgetOptions | translateOrder"
                  [value]="item"
                  >{{ item.translationKey | transloco }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="options-form options-form--header">
        <div class="row">
          <div class="col-12">
            <p>
              {{ 'businesspartner.properties.customer-contacts' | transloco }}
            </p>
          </div>
        </div>
      </div>
      <div class="options-form">
        <div class="row">
          <div class="col-12">
            <ceres-user-picker
              (selected)="changeMainCustomerContact($event)"
              [appUser]="true"
              [condition]="pickerCondition('mainCustomerContact')"
              [dpt]="true"
              [extern]="false"
              [required]="true"
              [value]="businessPartner.mainCustomerContact"
              placeholder="{{
                'businesspartner.properties.customer-contact' | transloco
              }}"
              [noCheck]="true"
            >
            </ceres-user-picker>
          </div>
          <div class="col-12">
            <ceres-user-picker
              (selected)="changeCustomerContact($event)"
              [appUser]="true"
              [dpt]="true"
              [extern]="false"
              [multiple]="true"
              [value]="businessPartner.customerContact"
              [noCheck]="true"
              buttonText="{{
                'businesspartner.properties.new-customer-contact' | transloco
              }}"
            ></ceres-user-picker>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-form-field class="mat-custom w-100" appearance="fill">
              <mat-label>{{
                'businesspartner.properties.personal-relation' | transloco
              }}</mat-label>
              <textarea formControlName="personalRelation" matInput></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 form-container margin">
      <div class="options-form options-form--header">
        <div class="row">
          <div class="col-12">
            <p>
              {{ 'businesspartner.properties.contact-information' | transloco }}
            </p>
          </div>
        </div>
      </div>
      <div class="options-form">
        <div class="row">
          <div class="col-sm-6" *ngIf="businessPartnerConfig.validateLabel('business-partner.phone')">
            <mat-form-field class="mat-custom w-100" appearance="fill">
              <mat-label>{{
                'businesspartner.properties.phone' | transloco
              }}</mat-label>
              <input type="tel" formControlName="phone" matInput />
            </mat-form-field>
          </div>
          <div class="col-sm-6" *ngIf="businessPartnerConfig.validateLabel('business-partner.fax')">
            <mat-form-field class="mat-custom w-100" appearance="fill">
              <mat-label>{{
                'businesspartner.properties.fax' | transloco
              }}</mat-label>
              <input type="tel" formControlName="fax" matInput />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <mat-form-field class="mat-custom w-100" appearance="fill">
              <mat-label>{{
                'businesspartner.properties.mobile' | transloco
              }}</mat-label>
              <input type="tel" formControlName="mobile" matInput />
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-form-field class="mat-custom w-100" appearance="fill">
              <mat-label>{{
                'shared.properties.location' | transloco
              }}</mat-label>
              <input formControlName="location" matInput />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12" *ngIf="!showEmail">
            <mat-form-field class="mat-custom w-100" appearance="fill">
              <mat-label>{{
                'shared.properties.e-mail' | transloco
              }}</mat-label>
              <input formControlName="eMail" matInput required />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="options-form options-form--header">
        <div class="row">
          <div class="col-12">
            <p>
              {{ 'businesspartner.properties.address-information' | transloco }}
            </p>
          </div>
        </div>
      </div>
      <div class="options-form">
        <div class="row">
          <div class="col-sm-6">
            <mat-form-field class="mat-custom w-100" appearance="fill">
              <mat-label>{{
                'businesspartner.properties.street' | transloco
              }}</mat-label>
              <input formControlName="street" matInput />
            </mat-form-field>
          </div>
          <div class="col-sm-6" *ngIf="businessPartnerConfig.validateLabel('business-partner.po-box')">
            <mat-form-field class="mat-custom w-100" appearance="fill">
              <mat-label>{{
                'businesspartner.properties.postal-box' | transloco
              }}</mat-label>
              <input formControlName="poBox" matInput />
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-form-field class="mat-custom w-100" appearance="fill">
              <mat-label>{{
                'businesspartner.properties.city' | transloco
              }}</mat-label>
              <input matInput formControlName="city" />
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-form-field class="mat-custom w-100" appearance="fill">
              <mat-label>{{
                'businesspartner.properties.zip' | transloco
              }}</mat-label>
              <input formControlName="zip" matInput maxlength="5" />
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-form-field class="mat-custom w-100" appearance="fill">
              <mat-label>{{
                'businesspartner.properties.cc' | transloco
              }}</mat-label>
              <input matInput formControlName="countryCode" />
            </mat-form-field>
          </div>
          <div class="col-sm-6" *ngIf="businessPartnerConfig.validateLabel('business-partner.country')">
            <mat-form-field class="mat-custom w-100" appearance="fill">
              <mat-label>{{
                'shared.properties.country' | transloco
              }}</mat-label>
              <input matInput formControlName="country" />
            </mat-form-field>
          </div>
          <div class="col-12"  *ngIf="businessPartnerConfig.validateLabel('business-partner.language')">
            <mat-form-field class="mat-custom w-100" appearance="fill">
              <mat-label>{{
                'shared.properties.language' | transloco
              }}</mat-label>
              <mat-select formControlName="language" required>
                <mat-option
                  *ngFor="let item of languageOptions | translateOrder"
                  [value]="item"
                >
                  {{ item.translationKey | transloco }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="options-form padding" [ngStyle]="{'background-color': checkLastTagsDisabled() ? 'white' : 'var(--ceres-primary-color-7)'}">
        <div class="row"  *ngIf="businessPartnerConfig.validateLabel('business-partner.status')">
          <div class="col-12">
            <mat-form-field class="mat-custom w-100" appearance="fill">
              <mat-label>{{
                'shared.properties.status' | transloco
              }}</mat-label>
              <mat-select formControlName="status" required>
                <mat-option
                  *ngFor="let item of statusOptions | translateOrder"
                  [value]="item"
                  >{{ item.translationKey | transloco }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 space-checkbox" *ngIf="businessPartnerConfig.validateLabel('business-partner.newsletter-candidate')">
            <mat-checkbox
              [disableControl]="
                businessPartner.advertisingBarrier ||
                businessPartner.newsletterBarrier
              "
              formControlName="newsletterCandidate"
              labelPosition="after"
              >{{
                'businesspartner.properties.newsletter-candidate' | transloco
              }}
            </mat-checkbox>
          </div>
          <div class="col-sm-6 space-checkbox" *ngIf="businessPartnerConfig.validateLabel('business-partner.ad-blocking')">
            <mat-checkbox
              [disableControl]="
                businessPartner.newsletterCandidate ||
                businessPartner.newsletterBarrier
              "
              formControlName="advertisingBarrier"
              labelPosition="after"
              >{{ 'businesspartner.properties.total-ad-blocking' | transloco }}
            </mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 space-checkbox" *ngIf="businessPartnerConfig.validateLabel('business-partner.newsletter-blocking')">
            <mat-checkbox
              [disableControl]="
                businessPartner.newsletterCandidate ||
                businessPartner.advertisingBarrier
              "
              formControlName="newsletterBarrier"
              labelPosition="after"
              >{{
                'businesspartner.properties.newsletter-blocking' | transloco
              }}
            </mat-checkbox>
          </div>

          <div class="col-sm-6 space-checkbox"  *ngIf="businessPartnerConfig.validateLabel('business-partner.checked-marked-for-deletion')">
            <mat-checkbox
              [disableControl]="businessPartner.inFocus"
              formControlName="deleteBookmarked"
              labelPosition="after"
              >{{
                'businesspartner.properties.marked-for-deletion' | transloco
              }}</mat-checkbox
            >
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 space-checkbox" *ngIf="businessPartnerConfig.validateLabel('business-partner.focus')">
            <mat-checkbox
              [disableControl]="businessPartner.deleteBookmarked"
              formControlName="inFocus"
              labelPosition="after"
              >{{
                'businesspartner.properties.checked-in-focus' | transloco
              }}</mat-checkbox
            >
          </div>
          <div class="col-sm-6 space-checkbox"  *ngIf="businessPartnerConfig.validateLabel('business-partner.christmas-card')">
            <mat-checkbox
              formControlName="christmasCard"
              labelPosition="after"
              >{{
                'businesspartner.properties.christmas-card' | transloco
              }}</mat-checkbox
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<ceres-loading *ngIf="!formGroup || busy"></ceres-loading>
