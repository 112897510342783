/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Inject, Injectable } from '@angular/core';
import { Activity, AdditionalRole, IUser, Location, RoleType } from '@ceres/domain';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { Employee, User } from './user';
import { catchError, filter, map } from 'rxjs/operators';
import { ImpersonatedHttpClient } from './http';
import { ENVIRONMENT } from '@ceres/frontend-helper';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/services/auth.service';

type EmployeeWithPermissions = IUser & {
  permissions?: Set<string>;
};

@Injectable({
  providedIn: 'root',
})
export class AppUserService {
  public loggedInUser$ = new BehaviorSubject<EmployeeWithPermissions | null>(null);
  public impersonatedEmployee$ = new BehaviorSubject<User | null>(null);
  public mainUser$ = new BehaviorSubject<User | null>(null);

  public additionalRole$ = new BehaviorSubject<AdditionalRole | null>(null);

  public permissions$ = this.loggedInUser$.pipe(
    filter((user) => !!user),
    map((user) => user?.permissions || []),
    map((perms) => new Set(perms)),
  );

  constructor(
    @Inject(ENVIRONMENT) private readonly environment: { edgeService: string, guestAccess: boolean },
    private readonly router: Router,
    private readonly http: ImpersonatedHttpClient,
    private readonly authService: AuthService,
  ) {
  }

  //#################
  //GETTER FUNCTIONS
  //#################

  public get portfolio() {
    const loggedInUser = this.loggedInUser$.getValue();
    return loggedInUser && loggedInUser.details?.portfolio ? loggedInUser.details.portfolio : null;
  }

  public get businessArea() {
    const loggedInUser = this.loggedInUser$.getValue();
    return loggedInUser && loggedInUser.details?.businessArea ? loggedInUser.details.businessArea : null;
  }

  public get businessAreaId(): number | null {
    const loggedInUser = this.loggedInUser$.getValue();
    return loggedInUser && loggedInUser.details?.businessArea ? loggedInUser.details.businessArea.id : null;
  }

  public get username(): string | null {
    const loggedInUser = this.loggedInUser$.getValue();
    return loggedInUser && loggedInUser.name ? loggedInUser.name : null;
  }

  public get id(): number | null {
    const loggedInUser = this.loggedInUser$.getValue();
    return loggedInUser ? loggedInUser.id : null;
  }

  public get team(): string | null {
    const loggedInUser = this.loggedInUser$.getValue();
    return loggedInUser && loggedInUser.details?.businessArea ? loggedInUser.details.businessArea.title : null;
  }

  public getPortfolioId(): number | null {
    const loggedInUser = this.loggedInUser$.getValue();
    return loggedInUser && loggedInUser.details?.portfolio ? loggedInUser.details?.portfolio.id ?? null : null;
  }

  public getBusinessAreaId(): number | null {
    const loggedInUser = this.loggedInUser$.getValue();
    return loggedInUser && loggedInUser.details?.businessArea ? loggedInUser.details?.businessArea.id ?? null : null;
  }

  public getAllBusinessAreaIds(): number[] | null {
    const loggedInUser = this.loggedInUser$.getValue();
    const allBusinessAreas = loggedInUser?.details?.allBusinessAreas;
    return allBusinessAreas ? allBusinessAreas.map(ba => ba.id) : null;
  }

  public getUserGID(): string | null {
    const loggedInUser = this.loggedInUser$.getValue();
    return loggedInUser && loggedInUser?.gid ? loggedInUser.gid : null;
  }

  public getLocation(): Location | null {
    const loggedInUser = this.loggedInUser$.getValue();
    return loggedInUser && loggedInUser.details?.location ? loggedInUser.details?.location : null;
  }

  public getPossibleActivities(): Activity[] {
    const loggedInUser = this.loggedInUser$.getValue();
    return !loggedInUser || !loggedInUser.details
      ? []
      : [
        {
          ...loggedInUser.details.defaultActivity,
          default: true,
        },
        ...loggedInUser.details.activities.sort((i1, i2) => {
          const name1 = i1.translationKey ?? i1.name;
          const name2 = i2.translationKey ?? i2.name;
          return name1.localeCompare(name2);
        }),
      ];
  }

  //#################
  //SESSION FUNCTIONS
  //#################

  public setMainUser(user: User) {
    if (user) {
      this.mainUser$.next(user);
      const storageImpersonated = localStorage.getItem('impersonatedEmployee');
      const storageOtherRole = localStorage.getItem('additionalRole');
      if (storageImpersonated) {
        const impersonation: User = JSON.parse(storageImpersonated);
        if (storageOtherRole) {
          const role = JSON.parse(storageOtherRole).role;
          impersonation.role = role;
          this.pushRoleChange(role);
        }
        this.pushImpersonatedUser(impersonation);
        this.pushLoggedInUser(impersonation);
      } else {
        if (storageOtherRole) {
          const role = JSON.parse(storageOtherRole);
          user.role = role.role;
          this.pushRoleChange(role);
        }
        this.pushLoggedInUser(user);
      }
    }
  }

  public pushLoggedInUser(user: IUser | null) {
    this.fetchCurrentPermissions().subscribe(
      (permissions) => {
        this.loggedInUser$.next(
          user
            ? { ...user, permissions }
            : null,
        );
      },
      () => this.loggedInUser$.next(user),
    );
  }

  public pushImpersonatedUser(user: User | null) {
    if (user) {
      localStorage.setItem('impersonatedEmployee', JSON.stringify(user));
    } else {
      localStorage.removeItem('impersonatedEmployee');
    }
    this.impersonatedEmployee$.next(user);
  }

  public clearStorage() {
    this.mainUser$.next(null);
    this.pushImpersonatedUser(null);
    this.pushLoggedInUser(null);
  }

  public logout(path: string = '/logout'): void {
    this.clearStorage();
    this.authService.logout();
    this.router.navigateByUrl(path);
  }

  pushRoleChange(selectedAdditionalRole: AdditionalRole | null) {
    if(selectedAdditionalRole) {
      localStorage.setItem('additionalRole', JSON.stringify(selectedAdditionalRole));
    } else {
      localStorage.removeItem('additionalRole');
    }
    this.additionalRole$.next(selectedAdditionalRole);
  }

  //#################
  //VALIDATION FUNCTIONS
  //#################
  //TODO refactor validation to use hasPermission pipe instead of role names

  private hasRole(role: RoleType): boolean {
    const loggedInUser = this.loggedInUser$.getValue();
    return !!(
      loggedInUser &&
      loggedInUser.role &&
      loggedInUser.role.name === role
    );
  }

  public isLoggedInUserCheckedByName(name: string): boolean {
    const loggedInUser = this.loggedInUser$.getValue();
    return loggedInUser?.name === name;
  }

  public isLoggedInUser(user: Employee): boolean {
    const loggedInUser = this.loggedInUser$.getValue();
    return loggedInUser?.gid === user?.gid;
  }

  public isAdmin(): boolean {
    return this.hasRole(RoleType.Admin);
  }

  public isTeamMember(): boolean {
    return this.hasRole(RoleType.TeamMember);
  }

  public isGroupLeader(): boolean {
    return this.hasRole(RoleType.GroupLeader);
  }

  public isTeamLeader(): boolean {
    return this.hasRole(RoleType.TeamLeader);
  }

  public isTeamLeaderCharging(): boolean {
    return this.hasRole(RoleType.TeamLeaderCharging);
  }

  public isMerchant(): boolean {
    // ändern wenn in employee new die checkboxen gelöscht werden. role dropdown verwenden
    return this.hasRole(RoleType.Merchant);
  }

  public isUser(): boolean {
    return this.hasRole(RoleType.User);
  }

  public isHoursTracker(): boolean {
    return this.hasRole(RoleType.HoursTracker);
  }

  public isGuest(): boolean {
    return this.hasRole(RoleType.Guest);
  }

  public isSupport(): boolean {
    return this.hasRole(RoleType.Support);
  }

  public isSpecialGID(): boolean {
    const loggedInUser = this.loggedInUser$.getValue();
    return !!(
      loggedInUser &&
      loggedInUser.gid &&
      (loggedInUser.gid === 'Z003JRRA' || loggedInUser.gid === 'Z004J4NS' || loggedInUser.gid === 'Z003HYNW' || loggedInUser.gid === 'Z004PR4J')
    );
  }

  public isTeamOrGroupLeaderOf(employee: Employee) {
    return (
      (employee.businessArea.id === this.businessArea?.id &&
        (this.isTeamLeader() || this.isTeamLeaderCharging())) ||
      (employee.portfolio.id === this.portfolio?.id && this.isGroupLeader())
    );
  }

  //#################
  //PERMISSION FUNCTIONS
  //#################

  private getPermissions() {
    const user = this.loggedInUser$.getValue();
    if (!user) {
      return new Set();
    }
    return user.permissions || new Set();
  }

  public hasPermission(permission: string) {
    return this.getPermissions().has(permission);
  }

  public hasAnyPermission(permissions: string[]) {
    const userPermissions = this.getPermissions();
    return permissions.some((p) => userPermissions.has(p));
  }

  //#################
  //UPDATE FUNCTIONS
  //#################

  private fetchCurrentPermissions() {
    return this.http
      .get<{ permissions: Array<string> }>(
        `${ this.environment.edgeService }/employees/current`,
      )
      .pipe(
        map((data) => data.permissions || []),
        map((permissions) => new Set(permissions)),
        catchError((e) => {
          if (e.status === 401) {
            return EMPTY;
          }
          throw e;
        }),
      );
  }

  //#################
  //#################
}
