import { isMoment, Moment } from 'moment';

export const adjustDateForTimezone = (d: Moment | Date): Date => {
  const date: Date = isMoment(d) ? d.toDate() : d;
  if (date) {
    const timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
    //GMT -X needs to be added and for GMT+X the offset needs to be substracted
    date.setTime(timeOffsetInMS > 0 ? date.getTime() + timeOffsetInMS : date.getTime() - timeOffsetInMS);
  }
  return date
}

export const getDateType = (d: Moment | Date): Date => {
  const date: Date = isMoment(d) ? d.toDate() : d;
  return date
}
