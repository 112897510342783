export const rolePermissions = {
  absences: 'absences',
  admin: 'admin',
  bop: {
    createOne: 'bop.createOne',
    editOne: 'bop.editOne',
    readAll: 'bop.readAll'
  },
  businesspartner: {
    createOne: 'businesspartner.createOne',
    editOne: 'businesspartner.editOne',
    readAll: 'businesspartner.readAll'
  },
  capacities: 'capacities',
  costReport: 'costReport',
  workingHoursReport: 'workingHoursReport',
  productivityReport: 'productivityReport',
  contract: {
    readAll: 'contract.readAll',
    editOne: 'contract.editOne',
    createOne: 'contract.createOne',
    reviewAll: 'contract.reviewAll',
    offertemplate: {
      readAll: 'contract.offer-template.readAll',
      editOne: 'contract.offer-template.editOne',
      createOne: 'contract.offer-template.createOne'
    }
  },
  controlling: {
    salesFunnel: 'controlling.salesFunnel',
    quality: 'controlling.quality'
  },
  employee: {
    readAll: 'employee.readAll',
    createOne: 'employee.createOne',
    editOne: 'employee.editOne',
    readOne: 'employee.readOne',
    skill: 'employee.skill'
  },
  myceres: {
    settings: 'myceres.settings',
    skills: 'myceres.skills'
  },
  program: {
    readAll: 'program.readAll',
    statusReport: {
      readAll: 'program.statusReport.readAll',
      createOne: 'program.statusReport.createOne',
      editOne: 'program.statusReport.editOne'
    },
    classification: 'program.classification'
  },
  project: {
    readExtern: 'project.readExtern',
    readAll: 'project.readAll',
    readOwn: 'project.readOwn',
    editOne: 'project.editOne',
    createOne: 'project.createOne',
    nrs: 'project.nrs',
    accounting: 'project.accounting',
    preStaffing: {
      readAll: 'project.preStaffing.readAll',
      editOne: 'project.preStaffing.editOne'
    },
    document: {
      editOwn: 'project.document.editOwn'
    }
  },
  reports: 'reports',
  salesActivity: {
    readAll: 'salesActivity.readAll',
    editOne: 'salesActivity.editOne',
    createOne: 'salesActivity.createOne'
  },
  services: {
    readAll: 'services.readAll',
    editAll: 'services.editAll'
  },
  task: 'task',
  guest: 'guest',
  support: 'support',
  timesheet: 'timesheet',
  // workload: 'workload',
  workload: {
    readAll: 'workload.readAll',
    changeValues: 'workload.changeValues'
  }
};
